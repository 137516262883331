import { useTranslations } from "next-intl";
import Link from "next/link";
import { useRouter } from "next/router";
import SocialMediaIcons from "./SocialMediaIcons";

const Contact = () => {
  const router = useRouter();
  const t = useTranslations("Contact");

  return (
    <section id="contact">
      <div className="py-20">
        <div className="container px-4 mx-auto">
          <div className="mb-12">
            <h2 className="text-4xl font-bold lg:text-5xl font-heading">
              {t("title")}
            </h2>
            <p className="leading-loose text-gray-500">{t("subtitle")}</p>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full mb-16 lg:w-1/2 lg:mb-0">
              <div className="flex flex-wrap">
                <div className="w-full mb-12 md:w-1/2 lg:w-1/2">
                  <h3 className="mb-2 text-3xl font-bold lg:text-4xl">
                    {t("clinic")}
                  </h3>
                  <Link href="https://www.google.com/maps/place/187+Angel+Pl,+London+N18+2UD,+UK/@51.6150739,-0.0669477,17z/data=!3m1!4b1!4m5!3m4!1s0x48761e93ff9b3033:0xe3ec514685e8c7e5!8m2!3d51.6150739!4d-0.064759">
                    <div className="cursor-pointer">
                      <p className="text-gray-400">
                        187 Angel Place, Fore Street
                      </p>
                      <p className="text-gray-400">
                        Upper Edmonton, London, N18 2UD
                      </p>
                    </div>
                  </Link>
                  <br />
                  <Link href="mailto:info@drbirseluckun.co.uk">
                    <p className="text-gray-400 cursor-pointer">
                      info@drbirseluckun.co.uk
                    </p>
                  </Link>
                  <br />
                  <Link href="tel:+442081338010">
                    <p className="text-gray-400 cursor-pointer">
                      +44 (2081) 338010
                    </p>
                  </Link>
                  <br />
                  <Link href="tel:+447586105088">
                    <p className="text-gray-400 cursor-pointer">
                      +44 (7586) 105088
                    </p>
                  </Link>
                </div>
                <div className="w-full md:w-1/3 lg:w-full">
                  <h3 className="mb-2 text-3xl font-bold lg:text-4xl">
                    {t("socials")}
                  </h3>
                  <div className="flex">
                    <SocialMediaIcons />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <div className="max-w-md lg:mx-auto">
                <form action="https://formspree.io/f/mdojnlor" method="POST">
                  <input type="hidden" name="_language" value={router.locale} />
                  <div className="mb-4">
                    <input
                      className="w-full p-4 text-xs font-semibold leading-none bg-gray-100 rounded outline-none"
                      type="text"
                      name="fullname"
                      placeholder={t("name")}
                    />
                  </div>
                  <div className="mb-4">
                    <input
                      className="w-full p-4 text-xs font-semibold leading-none bg-gray-100 rounded outline-none"
                      type="email"
                      name="email"
                      placeholder={t("email")}
                    />
                  </div>
                  <div className="mb-4">
                    <input
                      className="w-full p-4 text-xs font-semibold leading-none bg-gray-100 rounded outline-none"
                      type="tel"
                      name="mobile"
                      placeholder={t("mobile")}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <textarea
                      className="w-full h-24 p-4 text-xs font-semibold leading-none bg-gray-100 rounded outline-none resize-none"
                      placeholder={t("message")}
                      name="message"
                      required
                    />
                  </div>
                  <div className="mb-4"></div>
                  <div className="flex flex-row-reverse">
                    <button className="inline-block px-6 py-2 font-bold leading-loose transition duration-200 bg-primary rounded-l-xl rounded-t-xl hover:bg-sky-700 text-gray-50">
                      {t("send")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
