import { useTranslations } from "next-intl";
import Image from "next/image";
import Link from "next/link";
import DrBirselUckun from "../public/dr_birsel_uckun.jpg";

const Hero = () => {
  const t = useTranslations("Hero");

  return (
    <div className="pt-12 pb-20 lg:pt-20 bg-gray-50">
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap -mx-4">
          <div className="flex items-center w-full px-4 mb-12 lg:w-1/2 lg:mb-0">
            <div className="w-full text-center lg:text-left">
              <div className="max-w-md mx-auto lg:mx-0">
                <h2 className="mb-3 text-4xl font-bold lg:text-5xl font-heading">
                  {t("hero")}
                </h2>
              </div>
              <div className="max-w-sm mx-auto lg:mx-0">
                <div>
                  <a
                    className="inline-block w-full px-6 py-2 mb-3 font-semibold leading-loose text-white transition duration-200 bg-primary lg:mb-0 lg:mr-3 lg:w-auto hover:bg-sky-700 rounded-l-xl rounded-t-xl"
                    href="#contact"
                  >
                    {t("contactNow")}
                  </a>
                  <Link href="/about" passHref>
                    <a className="inline-block w-full px-6 py-2 font-semibold leading-loose transition duration-200 bg-white lg:w-auto hover:bg-gray-50 rounded-l-xl rounded-t-xl">
                      {t("aboutUs")}
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full px-4 lg:w-1/2">
            <div className="flex justify-center lg:mb-4 lg:ml-6">
              <Image
                src={DrBirselUckun}
                alt="Dr Birsel Uçkun"
                placeholder="blur"
                width={600}
                height={600}
                className="object-cover rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
