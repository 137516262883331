import type { GetStaticProps, NextPage } from "next";
import Image from "next/image";
import { useTranslations } from "next-intl";
import Contact from "../components/Contact";
import Hero from "../components/Hero";
import Layout from "../components/Layout";
import Testimonials from "../components/Testimonials";
import Link from "next/link";
import sdk from "../lib/sdk";
import { TestimonialsQuery } from "../interfaces";
import { NextSeo } from "next-seo";

type Props = {
  testimonials: TestimonialsQuery["testimonials"];
};

const Home = ({ testimonials }: Props) => {
  const t = useTranslations("Home");

  return (
    <Layout>
      <NextSeo title={t("title")} description={t("description")} />
      <Hero />
      <section>
        <div className="py-20">
          <div className="container px-4 mx-auto">
            <div className="flex flex-wrap mb-10">
              <div className="flex flex-col w-full mb-10 justify-evenly lg:mb-0 lg:w-1/2 md:pr-10">
                <h2 className="mb-2 text-4xl font-bold lg:text-5xl font-heading">
                  {t("bioresonance")}
                </h2>
                <p className="leading-loose text-gray-500">
                  {t("bioresonanceDescription")}
                </p>
                <div className="w-full text-center lg:w-auto lg:ml-auto">
                  <Link href="/bioresonance" passHref>
                    <a className="inline-block px-6 py-2 font-bold leading-loose text-white transition duration-200 bg-primary hover:bg-sky-700 rounded-l-xl rounded-t-xl">
                      {t("discoverMore")}
                    </a>
                  </Link>
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <Image
                  src="/pyramids.jpg"
                  alt="Rayonex Pyramids"
                  width={720}
                  height={405}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-4">
              <div className="w-full px-4 mb-12 lg:mb-0 md:w-1/2 lg:w-1/3">
                <h3 className="mb-2 text-2xl font-bold font-heading">
                  {t("box1")}
                </h3>
                <p className="leading-loose text-gray-500">
                  {t("box1description")}
                </p>
              </div>
              <div className="w-full px-4 mb-12 lg:mb-0 md:w-1/2 lg:w-1/3">
                <h3 className="mb-2 text-2xl font-bold font-heading">
                  {t("box2")}
                </h3>
                <p className="leading-loose text-gray-500">
                  {t("box2description")}
                </p>
              </div>
              <div className="w-full px-4 lg:w-1/3">
                <h3 className="mb-2 text-2xl font-bold font-heading">
                  {t("box3")}
                </h3>
                <p className="leading-loose text-gray-500">
                  {t("box3description")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonials testimonials={testimonials} />
      <Contact />
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const { testimonials } = await sdk.Testimonials();
  return {
    props: {
      testimonials: testimonials,
      messages: (await import(`../messages/${locale}.json`)).default,
    },
  };
};

export default Home;
