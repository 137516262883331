import {
  faArrowLeft,
  faArrowRight,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "next-intl";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import { TestimonialsQuery } from "../interfaces";

type Props = {
  testimonials: TestimonialsQuery["testimonials"];
};

const Testimonials = ({ testimonials }: Props) => {
  const t = useTranslations("Testimonials");
  const [activeTestimonial, setActiveTestimonial] = useState(0);
  const testimonialsJsx = testimonials.map((testimonial) => (
    <div
      className="flex flex-wrap w-full bg-white rounded shadow"
      key={testimonial.id}
    >
      <div className="flex flex-col justify-center w-full py-10 text-center border-r lg:w-1/3">
        <span className="text-5xl font-bold lg:text-6xl">
          {testimonial.rating}
        </span>
        <div className="flex justify-center mb-6 text-sky-600 lg:mb-12">
          {[...Array(testimonial.rating)].map((_, i) => (
            <FontAwesomeIcon icon={faStar} key={i} />
          ))}
        </div>
      </div>
      <div className="w-full px-6 py-10 lg:w-2/3">
        <Image
          className="mb-10"
          src="/left-quote.png"
          alt="Quote Mark"
          width={20}
          height={20}
        />
        <p className="mb-10 text-xl leading-loose text-gray-500 lg:text-2xl">
          {testimonial.testimonial}
        </p>
        <h4 className="text-2xl font-bold font-heading">{testimonial.name}</h4>
        {testimonial.title && (
          <p className="text-gray-500">{testimonial.title}</p>
        )}
      </div>
    </div>
  ));

  return (
    <section>
      <div className="py-20 bg-gray-50">
        <div className="container px-4 mx-auto">
          <div className="flex-col items-center justify-center lg:flex md:space-x-8">
            <div className="mb-10 space-x-5 text-center">
              <button
                className="p-4 mr-6 text-sky-600 transition duration-200 bg-white rounded-full shadow-md lg:mr-0 hover:text-sky-400"
                onClick={() =>
                  activeTestimonial > 0 &&
                  setActiveTestimonial(activeTestimonial - 1)
                }
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <button
                className="p-4 text-sky-600 transition duration-200 bg-white rounded-full shadow-md hover:text-sky-400"
                onClick={() =>
                  activeTestimonial < testimonialsJsx.length - 1 &&
                  setActiveTestimonial(activeTestimonial + 1)
                }
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
            {testimonialsJsx[activeTestimonial]}
          </div>
        </div>
        <div className="flex justify-center flex-grow mt-16">
          <Link href="/review" passHref>
            <button className="inline-block px-6 py-2 font-bold leading-loose text-white transition duration-200 bg-primary hover:bg-sky-700 rounded-l-xl rounded-t-xl">
              {t("write")}
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
